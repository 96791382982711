import { makeStyles } from '@mui/styles';
import { isMac, isWindows } from '../../../utils';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 4,
        height: '100%',
        background: theme.palette.ava.darkBg2,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        WebkitAppRegion: isMac ? 'drag' : undefined,
    },
    inputContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 6,
    },
    closeKeyboardInputButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 110,
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        WebkitAppRegion: isWindows ? 'no-drag' : undefined,
    },
    keyboardArrowUpIcon: {
        fontSize: 30,
        cursor: 'pointer',
    },
    messageContainer: {
        flex: 1,
        display: 'flex',
        flexBasis: '80%',
    },
    input: {
        flex: 1,
        fontSize: (props) => props.fontSize || 20,
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        resize: 'none',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 4,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.ava.blue2,
            borderRadius: 8,
        },
    },
    tooltip: {
        padding: '2.5px 8px',
        maxWidth: 230,
        backgroundColor: theme.palette.ava.grey4,
        borderRadius: 4,
        fontSize: 14,
        textAlign: 'center',
    },
    tooltipOutlined: {
        border: `1px solid ${theme.palette.ava.grey6}`,
    },
    arrow: {
        '&:before': {
            border: `1px solid ${theme.palette.ava.grey6}`,
        },
    },
    sendTooltipPlacementTop: {
        margin: '4px 0',
    },
    switchTooltipPlacementTop: {
        margin: '12px 0',
    },
    sendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: 10,
        flexBasis: '10%',
    },
    sendButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 32,
        width: 32,
        stroke: 'white',
        strokeWidth: 0.5,
        color: theme.palette.ava.deepBlue,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.ava.blue1,
        },
    },
    sendIcon: {
        fontSize: 18,
    },
    voiceContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        height: 30,
        visibility: 'hidden',
    },
    voiceMessage: {
        marginRight: 12,
        color: theme.palette.ava.grey6,
        fontSize: 12,
        lineHeight: '16px',
    },
    sendVoiceMessageText: {
        color: theme.palette.ava.blue2,
    },
    voiceSwitch: {
        marginRight: 20,
    },
}));
export default useStyles;
