var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LocalStorage from '../../services/localStorage';
import { sleep } from '../../utils';
export var VoicingProfiles;
(function (VoicingProfiles) {
    VoicingProfiles[VoicingProfiles["Undefined"] = 0] = "Undefined";
    VoicingProfiles[VoicingProfiles["Female"] = 1] = "Female";
    VoicingProfiles[VoicingProfiles["Male"] = 2] = "Male";
    //? to be added later
    // Custom = 3,
})(VoicingProfiles || (VoicingProfiles = {}));
const initialState = {
    ttsVoices: [],
    ttsError: '',
    voicingProfile: LocalStorage.get('voicingProfile') || VoicingProfiles.Undefined,
    isUsingTts: false,
};
export const textToSpeechSlice = createSlice({
    name: 'textToSpeech',
    initialState,
    reducers: {
        setTtsVoices(state, { payload }) {
            state.ttsVoices = payload;
        },
        setTtsError(state, { payload }) {
            state.ttsError = payload;
        },
        setVoicingProfile(state, { payload }) {
            state.voicingProfile = payload;
        },
        setIsUsingTts(state, { payload }) {
            state.isUsingTts = payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(findBestTTSVoice.fulfilled, (state, { payload }) => {
            state.ttsBestVoice = payload;
        });
    },
});
export const textToSpeechReducer = textToSpeechSlice.reducer;
export const { setTtsError, setTtsVoices, setVoicingProfile, setIsUsingTts } = textToSpeechSlice.actions;
export const setErrorAndReset = createAsyncThunk('textToSpeech/setErrorAndReset', (error, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    thunkAPI.dispatch(setTtsError(error));
    yield sleep(2000);
    thunkAPI.dispatch(setTtsError(''));
}));
export const fetchTTSVoices = () => (dispatch) => {
    if (window.speechSynthesis) {
        const populateVoiceList = () => {
            const newVoices = window.speechSynthesis.getVoices();
            if (newVoices) {
                dispatch(setTtsVoices(newVoices));
            }
        };
        populateVoiceList();
        if (window.speechSynthesis.onvoiceschanged !== undefined) {
            window.speechSynthesis.onvoiceschanged = populateVoiceList;
        }
    }
};
export const findBestTTSVoice = createAsyncThunk('textToSpeech/findBestTTS', (_, { getState }) => {
    const state = getState();
    const { speechLang } = state.scribeConversation;
    const { ttsVoices } = state.textToSpeech;
    let voice;
    if (window.speechSynthesis && ttsVoices.length) {
        let candidateVoices = ttsVoices.filter((v) => v.lang === speechLang);
        if (!candidateVoices.length) {
            const pattern = /-|_/;
            const shortLang = speechLang.split(pattern)[0];
            candidateVoices = ttsVoices.filter((v) => v.lang.split(pattern)[0] === shortLang);
        }
        if (candidateVoices.length === 1)
            [voice] = candidateVoices;
        if (candidateVoices.length > 1) {
            // TODO: Select the best candidate based on user's gender.
            // "Male", "Female", "David", "Zira" are good indicators for gender
            // of the voice for English voices.
            [voice] = candidateVoices;
        }
    }
    return voice;
});
