import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UserIcon from '../../components/Icons/UserIcon';
import InputField from '../../components/Inputs/InputField';
import { selectAvaName } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import HearingProfileDropdown from './HearingProfileDropdown';
import SectionContainer from './SectionContainer';
import SectionDivider from './SectionDivider';
import SectionTitle from './SectionTitle';
import UserNameInput from './UserNameInput';
//? We'll bring this back in the next iteration of tts with the new API
// import VoicingProfileDropdown from './VoicingProfileDropdown';
const useStyles = makeStyles((theme) => ({
    profile: {
        paddingTop: 32,
        paddingBottom: 32,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },
    photo: {
        marginBottom: 20,
    },
    subtitle: {
        marginBottom: 16,
        color: theme.palette.mode === 'light' ? theme.palette.ava.grey5 : theme.palette.ava.white,
        fontSize: 16,
        fontWeight: 400,
    },
    basicInfo: {
        display: 'grid',
        gridTemplateRows: 'repeat(2, [row-start] 1fr [row-end])',
        gridTemplateColumns: 'repeat(2, [col-start] 1fr [col-end])',
        minHeight: 264,
    },
    loginInfo: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, [col-start] 1fr [col-end])',
        minHeight: 80,
    },
}));
const MyProfile = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const avaName = useAppSelector(selectAvaName);
    return (React.createElement(React.Fragment, null,
        React.createElement(SectionContainer, { className: classnames(classes.profile) },
            React.createElement("div", { className: classes.photo },
                React.createElement("div", { className: classes.subtitle }, t('myProfile.yourPhoto')),
                React.createElement(UserIcon, { width: 84, height: 84 })),
            React.createElement(SectionTitle, null, t('myProfile.basicInfo')),
            React.createElement(SectionDivider, null),
            React.createElement("section", { className: classes.basicInfo },
                React.createElement("div", null,
                    React.createElement("div", { className: classes.subtitle }, t('myProfile.FullName')),
                    React.createElement(UserNameInput, null)),
                React.createElement("div", null,
                    React.createElement("div", { className: classes.subtitle }, t('myProfile.avaName')),
                    React.createElement(InputField, { value: avaName, disabled: true })),
                React.createElement("div", null,
                    React.createElement("div", { className: classes.subtitle }, t('myProfile.hearingProfile')),
                    React.createElement(HearingProfileDropdown, null))))));
};
export default MyProfile;
