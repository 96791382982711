export function parseWord(rawWord) {
    const parsedWord = { text: rawWord, bold: false, disabled: false };
    while (true) {
        const { text } = parsedWord;
        const firstChar = text[0];
        const lastChar = text[text.length - 1];
        if (firstChar === '`' && lastChar === '`') {
            parsedWord.bold = true;
        }
        else if (firstChar === '~' && lastChar === '~') {
            parsedWord.disabled = true;
        }
        else {
            break;
        }
        parsedWord.text = text.slice(1, -1);
    }
    return parsedWord;
}
export function combineParsedWords(parsedWords) {
    if (parsedWords.length === 0) {
        return [];
    }
    const combined = [];
    let currentBold = parsedWords[0].bold;
    let currentDisabled = parsedWords[0].disabled;
    let currentTexts = [];
    for (const word of parsedWords) {
        if (word.bold !== currentBold || word.disabled !== currentDisabled) {
            combined.push({ text: currentTexts.join(' '), bold: currentBold, disabled: currentDisabled });
            currentTexts = [];
            currentBold = word.bold;
            currentDisabled = word.disabled;
        }
        currentTexts.push(word.text);
    }
    combined.push({ text: currentTexts.join(' '), bold: currentBold, disabled: currentDisabled });
    return combined;
}
