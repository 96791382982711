import SendIcon from '@mui/icons-material/Send';
import { Tooltip } from '@mui/material';
import Fab from '@mui/material/Fab';
import Input from '@mui/material/Input';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { scribeCreateTranscript, scribeDeselectTranscriptWord } from '../../actions';
import useRecallAIManager from '../../hooks/useRecallAIManager';
import { selectAudioV2Status } from '../../selectors/audioV2';
import { selectRecallAIStatus } from '../../selectors/recallAI';
import { selectIsUsingTts, selectTtsBestVoice } from '../../selectors/textToSpeech';
import { selectFontSize, selectKeyboardInputShown } from '../../selectors/ui';
import { selectAvaId } from '../../selectors/userProfile';
import { RecordingStatus, startRecording, stopRecording } from '../../store/slices/audioV2';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { attemptSpeakWithVoice } from '../../utils/textToSpeech';
import TtsButton from '../textToSpeech/TtsButton';
const useStyles = makeStyles((theme) => ({
    input: {
        position: 'absolute',
        bottom: 30,
        right: 160,
        width: 'calc(100% - 180px)',
        fontSize: (props) => props.fontSize,
    },
    fab: {
        position: 'absolute',
        bottom: 30,
        color: theme.palette.ava.white,
        zIndex: 101,
        '&:hover': {
            // I couldn't find a way to overwrite the behavior without !important
            // even in style/sx props of the component
            backgroundColor: `${theme.palette.ava.deepBlue} !important`,
        },
    },
}));
const KeyboardInput = ({ scribeCreateTranscript, scribeDeselectTranscriptWord }) => {
    const fontSize = useAppSelector(selectFontSize);
    const classes = useStyles({ fontSize });
    const theme = useTheme();
    const { t } = useTranslation();
    const avaId = useAppSelector(selectAvaId);
    const recording = useAppSelector(selectAudioV2Status) === RecordingStatus.RECORDING;
    const keyboardInputShown = useAppSelector(selectKeyboardInputShown);
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const recallAIManager = useRecallAIManager();
    const ttsBestVoice = useAppSelector(selectTtsBestVoice);
    const isUsingTts = useAppSelector(selectIsUsingTts);
    const dispatch = useAppDispatch();
    const [value, setValue] = useState('');
    const sendMessage = (event) => {
        event.preventDefault();
        event.stopPropagation();
        scribeDeselectTranscriptWord();
        scribeCreateTranscript(undefined, value, avaId);
        scribeDeselectTranscriptWord();
        setValue('');
    };
    const onInputChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        scribeDeselectTranscriptWord();
        setValue(event.target.value);
    };
    const onKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            // User is pressing Enter without Shift, allow the default action of
            // sending a message.
            event.preventDefault();
            handleSend(event);
        }
    };
    const onSynthesizeSpeech = (event) => {
        if (!ttsBestVoice)
            return;
        sendMessage(event);
        if (recallAIStatus === 'CAPTIONING') {
            recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.handleTextToSpeech(value);
        }
        else {
            attemptSpeakWithVoice({
                message: value,
                recording,
                startRecord: () => dispatch(startRecording()),
                stopRecord: () => dispatch(stopRecording()),
                voice: ttsBestVoice,
            });
        }
    };
    const handleSend = useCallback((e) => {
        if (!isUsingTts) {
            sendMessage(e);
        }
        else {
            onSynthesizeSpeech(e);
        }
    }, [isUsingTts, value]);
    return (React.createElement(React.Fragment, null,
        keyboardInputShown && (React.createElement("form", { onSubmit: sendMessage },
            React.createElement(Slide, { direction: "left", in: true, mountOnEnter: true, unmountOnExit: true },
                React.createElement(Input, { classes: { root: classes.input }, onChange: onInputChange, value: value, "data-qa": "ttsInputField", placeholder: t('conversation.typeTranscriptHere'), onKeyDown: onKeyDown, autoFocus: true, multiline: true, minRows: '1' })))),
        keyboardInputShown && ttsBestVoice && React.createElement(TtsButton, null),
        keyboardInputShown && (React.createElement(Tooltip, { title: !isUsingTts ? t('ccMode.keyboard.tooltip.send') : t('ccMode.keyboard.sendAsVoiceMessage') },
            React.createElement(Fab, { classes: { root: classes.fab }, "data-qa": "sendTTS", onClick: handleSend, style: {
                    right: 20,
                    backgroundColor: theme.palette.ava.blue,
                } },
                React.createElement(SendIcon, null))))));
};
const mapDispatchToProps = {
    scribeCreateTranscript,
    scribeDeselectTranscriptWord,
};
export default connect(({}) => ({}), mapDispatchToProps)(KeyboardInput);
